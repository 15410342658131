export class User {
    public id: number;
    public color_id: number | null | undefined;
    public name: string;
    public email: string;
    public is_admin: number;
    public created_at: Date | null | undefined;
    public updated_at: Date | null | undefined;
    public deleted_at: Date | null | undefined;

    constructor(other?: Partial<User>){
        this.id = other?.id ?? 0;
        this.color_id = other?.id;
        this.name = other?.name ?? "";
        this.email = other?.email ?? "";
        this.is_admin = other?.is_admin ?? 0;
        this.created_at = other?.created_at;
        this.updated_at = other?.updated_at;
        this.deleted_at = other?.deleted_at;
    }
}