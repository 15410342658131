import React, { useCallback, useContext, useState } from 'react';
import { Box } from '@mui/system';
import { DialogTitle, DialogContent, DialogActions, Button  } from '@mui/material';
import { UserService } from 'Services';
import { ColorPicker, PasswordChange } from 'Components';
import { useTranslation } from 'react-i18next';
import { UserContext, UsersContext } from 'Contexts'; 
import { setLoading, removeLoading } from 'Utils'; 
import { CustomError } from 'Helpers';


export interface SettingsProps {
  onCloseClick: () => void
}

export const Settings: React.FC<SettingsProps> = ({ onCloseClick }) => {

  const { t } = useTranslation();
  const { user, setUserState } = useContext(UserContext);
  const { users, setUsers } = useContext(UsersContext);
  const [customError, setCustomError] = useState<CustomError>();

  const handleOnSelect = useCallback((colorId) => {
    setUserState(prevState => ({ ...prevState, loading: setLoading(prevState.loading, "colorPicker") }));
      UserService.ChangeColor(colorId)
      .subscribe({
        next: (user) => {
          setUserState(prevState => {
            prevState.user.color_id = user.color_id;
            return { ...prevState, loading: removeLoading(prevState.loading, "colorPicker") };
          });

          setUsers(prevState => {
            prevState[users.findIndex(x =>  x.id === user.id )].color_id = user.color_id;
            return [...prevState];
          });
        },
        error: (e) => {
          setCustomError(e);
          console.error(`subscribe() received the error: ${e}`)
        }
      });
  }, [setUserState, setUsers, users]);

  const handleOnChangePassword = useCallback((current_password: string, password: string, password_confirmation: string, callback: () => void) => {
      setUserState(prevState => ({ ...prevState, loading: setLoading(prevState.loading, "password") }));
      UserService.ChangePassword(current_password, password, password_confirmation)
      .subscribe({
        next: (i) => {
          setCustomError(undefined);
          setUserState(prevState => {
            return { ...prevState, loading: removeLoading(prevState.loading, "password") };
          });
          callback();
        },
        error: (e) => {
          setCustomError(e);
          setUserState(prevState => {
            return { ...prevState, loading: removeLoading(prevState.loading, "password") };
          });
        }
      });
  }, [setUserState]);

  return (
    <>
      <DialogTitle>{t('settings')}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection={{ xs: "column-reverse", lg: "row" }}> 
          <PasswordChange error={customError} onPasswordChange={handleOnChangePassword} />
          <ColorPicker selectedColorId={user.color_id ?? undefined} onSelected={handleOnSelect} />
        </Box>
      </DialogContent>
      <DialogActions>
          <Button variant="outlined" color="info" onClick={onCloseClick}>{t('close')}</Button>
      </DialogActions>
    </>
  );
}

export default Settings;