import { useEffect, useState } from 'react';
import { Board } from 'Models';
import { BoardService } from 'Services';
import { switchMap, timer } from 'rxjs';

export interface BoardState {
    board: Board;
    isLoading: boolean
}

export const useActiveBoard = (board_id?: number) => {
    const [boardState, setBoardState] = useState<BoardState>({ board: new Board(), isLoading: true });

    useEffect(() => {
      const obs = timer(0, 10000)
        .pipe(
          switchMap(() => board_id ? BoardService.GetBoard(board_id) : BoardService.GetActiveBoard())
        ).subscribe({
          next: board => {
            setBoardState({ board, isLoading: false });
          }
        });
        return () => {
          obs.unsubscribe()
        }
      }, [board_id]);

    return { ...boardState, setBoardState };
}

export const useMaxWordCount = () => {
  const [maxWordCount, setMaxWordCount] = useState<number>(75);

  useEffect(() => {
    BoardService.GetMaxWordCount()
      .subscribe({
        next: maxWordCount => {
          setMaxWordCount(maxWordCount);
        }
      });
    }, []);

  return { maxWordCount, setMaxWordCount };
}