import React, { Dispatch, SetStateAction } from 'react';
import { Color } from 'Models';

export interface ColorsStateInterface {
    colors: Color[], 
    setColors: Dispatch<SetStateAction<Color[]>>
}

export const ColorsContext = React.createContext<ColorsStateInterface>({
    colors: [],
    setColors: () => {}
});