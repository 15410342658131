import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import * as Pages from 'Pages';
import reportWebVitals from './reportWebVitals';
import { GlobalProvider } from 'Contexts';
import i18n from './i18n';

import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#005EB8'
    },
    mode: "dark"
  }
})

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <GlobalProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Pages.App />
        </ThemeProvider>
      </GlobalProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
