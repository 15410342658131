import React, { useEffect, useCallback, useState, useContext } from 'react';
import { Box } from '@mui/system';
import { Grid, Tooltip, Radio, Button } from '@mui/material';
import { Check, AccountCircle } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { UserContext, UsersContext, ColorsContext } from 'Contexts';
import { useTranslation } from 'react-i18next';
import { isLoading } from 'Utils'; 

export interface ColorPickerProps {
    selectedColorId?: number,
    onSelected: (colorId: number) => void
}

export const ColorPicker: React.FC<ColorPickerProps> = ({ selectedColorId, onSelected }) => {

    const { t } = useTranslation();
    const [colorId, setColorId] = useState<number>(0);

    const { colors } = useContext(ColorsContext);
    const userContext = useContext(UserContext);
    const user = userContext.user;
    const colorPickerIsLoading = isLoading(userContext.loading, "colorPicker");
    const { users } = useContext(UsersContext);

    useEffect(() => {
        if (selectedColorId) {
            setColorId(selectedColorId);
        }

    }, [selectedColorId]);

    const handleChangeHue = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const hue = Number(event.target.value);
        setColorId(hue);
      }, []);

    const handleOnClick = useCallback(() => {
        onSelected(colorId);
    }, [colorId, onSelected]);

    return (
        <Box sx={{ m:2, width: 240  }}  >
        <Grid item xs={12} sm={6} md={4}  >
          <Box sx={{ width: 240 }}>
            {colors.map((color) => {
                const backgroundColor = color.code;
                const userColor = users.find(x => x.color_id === color.id && x.id !== user.id );
                const isDisabled = userColor !== undefined;
                const tooltipTitle = isDisabled ? t('colorAlreadyUsed', { user: userColor.name }) : color.name;
  
              return (
                <Tooltip placement="right" title={tooltipTitle} key={color.id} disableInteractive>
                    <span> 
                        
                        <Radio
                            sx={{ p: 0 }}
                            color="default"
                            checked={colorId === color.id}
                            onChange={handleChangeHue}
                            value={color.id}
                            name={color.name}
                            disabled={isDisabled}
                            icon={
                                <>
                                {
                                    isDisabled ? (
                                        <Box
                                        sx={{
                                        width: 48,
                                        height: 48,
                                        color: 'common.white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        }}
                                        style={{ backgroundColor }}
                                    >
                                        <AccountCircle style={{ fontSize: 30 }} />
                                    </Box>
                                    ) :
                                        (
                                        <Box
                                            sx={{ width: 48, height: 48 }}
                                            style={{ backgroundColor }}
                                        />)
                                }
                                </>
                            }
                            checkedIcon={
                            <Box
                                sx={{
                                width: 48,
                                height: 48,
                                border: 1,
                                borderColor: 'white',
                                color: 'common.white',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                }}
                                style={{ backgroundColor }}
                            >
                                <Check style={{ fontSize: 30 }} />
                            </Box>
                            }
                        />
                    </span>
                </Tooltip>
              );
            })}
          </Box>
        </Grid>
        <Box display="flex" justifyContent="flex-end">

            {
                colorPickerIsLoading ? 
                (<LoadingButton variant="contained" sx={{ mt:2 }} loading >{t('saveColor')}</LoadingButton>)
                :
                (<Button variant="contained" color="primary" onClick={handleOnClick} sx={{ mt:2 }} disabled={ colorId === 0 || user.color_id === colorId } >{t('saveColor')}</Button>)
            }
        </Box>
        </Box>
      );
}

export default ColorPicker;