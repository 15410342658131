export const isLoading = (loading: string[], value: string): boolean => {
    return loading.includes(value);
}

export const setLoading = (loading: string[], value: string) => {
    loading.push(value);
    return loading;
}

export const removeLoading = (loading: string[], value: string) => {
    return arrayRemove(loading, value);
}

const arrayRemove = <T>(arr: T[], value: T): T[] => { 
    return arr.filter(function(ele){ 
        return ele !== value; 
    });
};