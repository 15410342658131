import { useContext, useState, useCallback } from 'react';
import { TextField, Button, Box, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { ActiveBoardContext } from 'Contexts';
import { MessageService } from 'Services';
import { CustomError } from 'Helpers';
import { useTranslation } from 'react-i18next';

const enterMessageContainerStyle: SxProps = {
  marginTop: 2,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 1
}

export const InputMessage: React.FC = () => {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>("");
  const [customError, setCustomError] = useState<CustomError>();
  const { board, setBoardState } = useContext(ActiveBoardContext);
  const theme = useTheme();
  
  const handleOnClickSendMessage = useCallback(() => {
    const messages = board.messages;
    const index = messages.length > 0 ? messages.length - 1 : -1;
    const position = (index > -1 ? Number(messages[index].position) : 0) + 1;

    MessageService.InsertMessage(board.id, position, message)
      .subscribe({
        next: message => {
          setMessage("");
          setCustomError(undefined);
          setBoardState(prevState => {
            prevState.board.messages.push(message);
            return { ...prevState };
          });
        },
        error: (e: CustomError) => {
          setCustomError(e);
        }
      });
  }, [board, message, setBoardState]);
  
  return (
      <Box display="flex">
        <Box sx={enterMessageContainerStyle}>
            <TextField
			        id="message"
              error={customError ? true : false}
              helperText={customError?.messages[0]}
              value={message}
              variant="outlined"
              label={t('message')}
              size="small"
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && message ? handleOnClickSendMessage() : undefined}
              inputProps={{
                style: {
                  backgroundColor: theme.palette.divider,
                  borderRadius: theme.shape.borderRadius
                }
              }}
            />
          <Button disabled={!message} variant="contained" color="primary" onClick={handleOnClickSendMessage}>{t('send')}</Button>
        </Box>
      </Box>
  );
}

export default InputMessage;