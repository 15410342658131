import { Message } from "Models";

export class Board {
    public id: number;
    public is_active: boolean;
    public created_at: Date;
    public updated_at: Date | undefined;
    public messages: Message[];
    
    constructor(other?: Partial<Board>) {
        this.id = other?.id ?? 0;
        this.is_active = other?.is_active ?? true;
        this.created_at = other?.created_at ?? new Date();
        this.updated_at = other?.updated_at;
        this.messages = other?.messages ?? [];
    }
}