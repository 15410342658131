import { useContext, useCallback, useEffect, useState, useMemo } from 'react';
import { Card, Box, Paper, Typography, Tooltip, Chip, Button, Theme } from '@mui/material';
import { Warning, ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { SxProps } from '@mui/system';
import { InputMessage } from 'Components';
import { ActiveBoardContext, ColorsContext, UsersContext, UserContext, SettingsContext } from 'Contexts';
import { useMaxWordCount } from 'Hooks';
import { Color, User } from 'Models';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { UserService } from 'Services';

const boxStyle: SxProps = {
  p: 2,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "calc(100vh - 64px)",
  width: "100vw",
  flexDirection: "column"
}

const paperStyle: SxProps = {
  p: 4,
  height: 640,
  minHeight: 640,
  maxHeight: 640,
  width: 900,
  minWidth: 900,
  maxWidth: 900,
  backgroundColor: "#002f5c"
}

const cardStyle: SxProps = {
  height: "100%",
  backgroundColor: "#999"
}

const chipStyle = (code: string) => ({
  m: 0.25,
  bgcolor: "inherit",
  p: 0,
  border: `1px solid rgba(0,0,0,0)`,
  borderRadius: 1,
  ":hover": {
    boxShadow: `0px 0px 5px 0px ${code}, 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)`,
    border: `1px solid ${code}`
  }
})

const messageStyle = (theme: Theme, code: string) => ({
  color: code,
  fontWeight: "bold",
  textShadow: `0 0 4px ${theme.palette.getContrastText(code)}`,
  fontSize: "2em"
});

export interface BoardProps {
  onChangeBoardId?: (board_id: number) => void;
}
export const Board: React.FC<BoardProps> = ({ onChangeBoardId }) => {
  const { t } = useTranslation();
  const { board } = useContext(ActiveBoardContext);
  const { users, setUsers } = useContext(UsersContext);
  const { colors } = useContext(ColorsContext);
  const { user } = useContext(UserContext);
  const { setIsOpen } = useContext(SettingsContext);
  const [, setRequestedBoardId] = useState<number>(board.id);
  const { maxWordCount } = useMaxWordCount();
  const currentWordCount = useMemo(() => board.messages.length, [board.messages]);

  useEffect(() => {
    setRequestedBoardId(board.id);
  }, [board]);

  useEffect(() => {
    let hasUserWithMissingColors = false;

    board.messages.forEach(message => {
      const user = users.find(u => u.id === message.user_id) ?? new User();
      if (!user.color_id) {
        hasUserWithMissingColors = true;
      }
    });

    if (hasUserWithMissingColors) {
      UserService.GetUsers()
        .subscribe({
          next: users => setUsers(users)
        });
    }
  }, [board.messages, setUsers, users])

  const handleSettingsOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const ColorTitle = () => (
    <Box textAlign="center">
      <Warning sx={{ my: 1 }} color="warning" />
      <Typography sx={{ my: 1 }}>{t('chooseColor')}</Typography>
      <Button sx={{ my: 1 }} variant="contained" color="primary" onClick={handleSettingsOpen}>{t('openSettings')}</Button>
    </Box>
  );

  const handleOnClickChangeBoard = (direction: "increment" | "decrement") => {
    setRequestedBoardId(prevState => {
      const newBoardId = direction === "increment" ? ++prevState : --prevState;
      if (onChangeBoardId) {
        onChangeBoardId(newBoardId);
      }
      return newBoardId;
    })
  }

  return (
    <Box sx={boxStyle}>
      <Box display="flex" alignContent="center" justifyContent="center">
        <Button sx={{ visibility: board.id > 1 ? "visible" : "hidden" }} onClick={() => handleOnClickChangeBoard("decrement")}><ArrowBackIosNew fontSize="large" /></Button>
        <Paper sx={paperStyle} elevation={4}>
          <Card variant="outlined" sx={cardStyle}>
            {
              board.messages.map(message => {
                const user = users.find(u => u.id === message.user_id) ?? new User();
                const color = colors.find(c => c.id === user.color_id) ?? new Color();
                color.code = color.code ? color.code : "rgba(0,0,0,0)";

                const Title = () => (
                  <Box>
                    <Typography align="center">{user.name}</Typography>
                    <Typography align="center" color="textSecondary">{moment(message.created_at).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                  </Box>
                );

                return (
                  <Tooltip key={message.id} title={<Title />} arrow placement="top">
                    <Chip sx={chipStyle(color.code)} label={<Typography sx={theme => messageStyle(theme, color.code)} key={message.id}>{message.text}</Typography>} />
                  </Tooltip>
                )
              })
            }
          </Card>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            <Typography color="textSecondary" lineHeight={2.5} variant="caption">{`${currentWordCount} / ${maxWordCount}`}</Typography>
          </Box>
        </Paper>
        <Button sx={{ visibility: !board.is_active ? "visible" : "hidden" }} onClick={() => handleOnClickChangeBoard("increment")}><ArrowForwardIos fontSize="large" /></Button>
      </Box>
      <Box sx={{ visibility: board.is_active ? "visible" : "hidden" }}>
        <Tooltip title={<ColorTitle />} arrow placement="right" open={!user.color_id}>
          <Box><InputMessage /></Box>
        </Tooltip>
      </Box>
    </Box>
  );
}

export default Board;