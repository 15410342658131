import { URL_API } from "Helpers";
import { Color } from "Models";
import { BYFetch, _getParams } from 'Services';
import { Observable } from 'rxjs';


export class ColorService {
    
    public static GetColors(): Observable<Color[]> {
        return BYFetch<Color[]>(`${URL_API}/color`, _getParams());
    }
}