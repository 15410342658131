import { URL_API } from "Helpers";
import { Message } from "Models";
import { Observable } from 'rxjs';
import { BYFetch, _postParams } from 'Services';



export class MessageService {
    public static InsertMessage(board_id: number, position: number, text: string): Observable<Message> {
        const formData = new FormData();
        formData.append("board_id", board_id.toString());
        formData.append("position", position.toString());
        formData.append("text", text);

        return BYFetch<Message>(`${URL_API}/message`, _postParams(undefined, formData));
    }
}