import React, { useContext } from 'react';
import { UserContext } from 'Contexts';
import { Login, Container } from './';
import { Box } from '@mui/system';

export const App: React.FC = () => {
  const { user } = useContext(UserContext);

  return (
    <Box height="100vh" width="100vw">
      {
        user.name ? <Container /> : <Login />
      }
    </Box>
  );
}

export default App;