import React, { Dispatch, SetStateAction } from 'react';

export interface SettingsStateInterface {
    isOpen: boolean, 
    setIsOpen: Dispatch<SetStateAction<boolean>>
}

export const SettingsContext = React.createContext<SettingsStateInterface>({
    isOpen: false,
    setIsOpen: () => {}
});