import { URL_API } from "Helpers";
import { Login, User } from "Models";
import { Observable, throwError } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { switchMap, catchError } from "rxjs/operators";
import { CustomError } from 'Helpers';

const params = (method: "POST" | "GET" | "PUT", headers?: Headers, body?: BodyInit, requestInit?: RequestInit): RequestInit => {
    const loginStr = localStorage.getItem("login");
    if (loginStr) {
        const login: Login = JSON.parse(loginStr) as Login;
        headers = headers ?? new Headers();
        headers.append("Authorization", `${login.token_type} ${login.token}`);
    }

    return {
        headers,
        body,
        method,
        ...requestInit
    }
}

export const _getParams = (headers?: Headers, body?: BodyInit, requestInit?: RequestInit) => params("GET", headers, body, requestInit);
export const _postParams = (headers?: Headers, body?: BodyInit, requestInit?: RequestInit) => params("POST", headers, body, requestInit);
export const _putParams = (headers?: Headers, body?: BodyInit, requestInit?: RequestInit) => params("PUT", headers, body, requestInit);;

export const BYFetch = <T>(url: string, params: RequestInit): Observable<T> => {
    const $data = fromFetch(url, params)
        .pipe(
            switchMap(async response => {
                if (response.ok) {
                    return response.json() as Promise<T>
                } else {
                    const error = JSON.parse(await response.text());
                    const messages: string[] = [];
                    const keys = Object.keys(error);
                    
                    keys.forEach(key => {
                        const value: string | string[] = error[key];
                        if (Array.isArray(value)) {
                            messages.push(...value);
                        } else {
                            messages.push(value);
                        }
                    });

                    const customError: CustomError = {
                        messages,
                        status: response.status,
                        statusText: response.statusText
                    }

                    throw customError;
                }
            }),
            catchError(error => {
                console.log(error);
                return throwError(() => error);
            })
        );

    return $data;
};

export class UserService {
    public static Login(email: string, password: string, remember: string): Observable<Login> {
        const formData = new FormData();
        formData.append("email", email);
        formData.append("password", password);
        formData.append("remember", remember);

        return BYFetch<Login>(`${URL_API}/login`, _postParams(undefined, formData));
    }

    public static Logout(): Observable<Login> {
        return BYFetch<Login>(`${URL_API}/logout`, _postParams());
    }

    public static ChangePassword(oldPassword: string, newPassword: string, newPasswordConfirmation: string): Observable<User> {
        const params = new URLSearchParams();
        params.append("old_password", oldPassword);
        params.append("new_password", newPassword);
        params.append("new_password_confirmation", newPasswordConfirmation);

        return BYFetch<User>(`${URL_API}/user/password`, _putParams(undefined, params));
    }

    public static GetLoggedInUser(): Observable<User> {
        return BYFetch<User>(`${URL_API}/me`, _postParams(undefined, undefined, { redirect: 'follow' }));
    }

    public static GetUsers(): Observable<User[]> {
        return BYFetch<User[]>(`${URL_API}/user`, _getParams());
    }

    public static ChangeColor(colorId: number): Observable<User> {
        const params = new URLSearchParams();
        params.append("color_id", colorId.toString());

        return BYFetch<User>(`${URL_API}/user/color`, _putParams(undefined, params ));
    }
}