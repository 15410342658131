import { URL_API } from "Helpers";
import { Board } from "Models";
import { Observable, of } from 'rxjs';
import { BYFetch, _getParams } from 'Services';



export class BoardService {
    public static GetActiveBoard(): Observable<Board> {
        return BYFetch<Board>(`${URL_API}/board/current`, _getParams());
    }

    public static GetBoard(id: number): Observable<Board> {
        return BYFetch<Board>(`${URL_API}/board/${id}`, _getParams());
    }

    public static GetBoards(): Observable<Board> {
        return BYFetch<Board>(`${URL_API}/board`, _getParams());
    }

    public static GetMaxWordCount(): Observable<number> {
        return of(75);
    }
}