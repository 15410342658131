import React, { Dispatch, SetStateAction } from 'react';
import { Board } from 'Models';
import { BoardState } from 'Hooks';

export interface ActiveBoardStateInterface {
    board: Board, 
    setBoardState: Dispatch<SetStateAction<BoardState>>,
    isLoading: boolean
}

export const ActiveBoardContext = React.createContext<ActiveBoardStateInterface>({
    board: new Board(),
    setBoardState: () => {},
    isLoading: false
});