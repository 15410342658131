import React from 'react';
import { UserProvider, UsersProvider, LoginProvider, ColorsProvider, SettingsProvider } from 'Contexts';

export const GlobalProvider: React.FC = ({children}) => {
    return (
        <UserProvider>
            <UsersProvider>
                <LoginProvider>
                    <ColorsProvider>
                        <SettingsProvider>
                            {children}
                        </SettingsProvider>
                    </ColorsProvider>
                </LoginProvider>
            </UsersProvider>
        </UserProvider>
    )
}