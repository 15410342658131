export class Login {
    public token: string;
    public token_type: string;
    public expires_in: number;

    constructor(other?: Partial<Login>) {
        this.token = other?.token ?? "";
        this.token_type = other?.token_type ?? "";
        this.expires_in = other?.expires_in ?? 0;
    }
}