import React, { useCallback, useEffect, useState, useContext } from 'react';
import { UserService } from 'Services';
import { Button, Checkbox, TextField, Paper, CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { LoginContext, UserContext } from 'Contexts';
import { useTranslation } from 'react-i18next';
import { removeLoading } from 'Utils'; 
import { CustomError } from 'Helpers';

export interface LoginProps {
}

export const Login: React.FC<LoginProps> = () => {
  const [loginUser, setLoginUser] = useState<{ email: string, password: string, remember: string }>({
    email: "",
    password: "",
    remember: "false"
  });
  const { t } = useTranslation();
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
  const [loggingIn, setLoggingIn] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const {login, setLogin } = useContext(LoginContext);
  const { setUserState } = useContext(UserContext);
  const [customError, setCustomError] = useState<CustomError>();
  
  const getLoggedInUser = useCallback(() => {
    UserService.GetLoggedInUser()
        .subscribe({
          next: (user) => {
            setLoading(false);
            setCustomError(undefined);
            setUserState(prevState => ({ user, loading: removeLoading(prevState.loading, "login") }));
            
          },
          error: (e) => {
            setLoading(false);
            setCustomError(e);
            setUserState(prevState => {
              return { ...prevState, loading: removeLoading(prevState.loading, "login") };
            });
          }
        });
  }, [setUserState]);

  useEffect(() => {

    if (login.token) 
    {
      getLoggedInUser();
    }
    else 
    {
      setLoading(false);
    }
  }, [getLoggedInUser, login]);

  const handleOnChangeLoginUser = useCallback((key: 'email' | 'password' | 'remember', value: string) => {
    setLoginUser(prevState => {
      prevState[key] = value;
      return { ...prevState };
    });
  }, []);

  const handleOnClickLogin = useCallback(() => {
    const errors: { [key: string]: boolean } = {};
    errors["email"] = !loginUser.email;
    errors["password"] = !loginUser.password;
    setErrors(errors);

    const errorKeys = Object.keys(errors);
    if (!errorKeys.some(key => errors[key])) {
      setLoggingIn(true);
      UserService.Login(loginUser.email, loginUser.password, loginUser.remember)
        .subscribe({
            next: (login) => {
              setLoggingIn(false);
              setCustomError(undefined);
              localStorage.setItem("login", JSON.stringify(login));
              setLogin(login);
              getLoggedInUser();
            },
            error: (e) => {
              setLoggingIn(false);
              setCustomError(e);
              setUserState(prevState => {
                return { ...prevState, loading: removeLoading(prevState.loading, "login") };
              });
            }
        });
    }
  }, [getLoggedInUser, loginUser.email, loginUser.password, loginUser.remember, setLogin, setUserState]);

  return (
  <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" height="100%">
    {
    loading || loggingIn ? (
            <>
              <Typography sx={{ marginBottom: 1 }}>{loading ? t('loading') : t('loggingIn')}</Typography>
              <CircularProgress  />
            </>
          ) : (
            <Paper sx={{ p: 4 }}>
              <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                <Box sx={{ p: 2 }}>
                  <TextField id="email" label={t('email')} value={loginUser.email} error={errors["email"]} onChange={(e) => handleOnChangeLoginUser("email", e.currentTarget.value)} />
                </Box>
                <Box sx={{ p: 2 }}>
                  <TextField 
                  id="password" 
                  helperText={customError?.messages[0]}
                  label={t('password')} 
                  value={loginUser.password} 
                  error={errors["password"] || (customError ? true : false)} 
                  onChange={(e) => handleOnChangeLoginUser("password", e.currentTarget.value)} 
                  type="password" />
                </Box>
                <Box sx={{ p: 1 }} display="flex" alignItems="center" justifyContent="center">
                  <Typography>{t('rememberMe')}</Typography>
                  <Checkbox sx={{ ml: 1 }} checked={(loginUser.remember === 'true')} onChange={(e) => handleOnChangeLoginUser("remember", e.currentTarget.checked.toString())} />
                </Box>
                <Box sx={{ p: 2 }}>
                  <Button variant="contained" color="primary" onClick={handleOnClickLogin}>{t('login')}</Button>
                </Box>
              </Box>
            </Paper>
        )
      }
        </Box>
  );
}

export default Login;