import React, { Dispatch, SetStateAction } from 'react';
import { Login } from 'Models';

export interface LoginStateInterface {
    login: Login, 
    setLogin: Dispatch<SetStateAction<Login>>, 
    isLoading: boolean
}

export const LoginContext = React.createContext<LoginStateInterface>({
    login: localStorage.getItem("login") ? JSON.parse(localStorage.getItem("login") ?? '') as Login : new Login(),
    setLogin: () => {},
    isLoading: false,
});