import React, { useCallback, useState, useContext } from 'react';
import { Box } from '@mui/system';
import { Typography, Button, TextField  } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'Contexts'; 
import { isLoading } from 'Utils'; 
import { CustomError } from 'Helpers';


export interface PasswordChangeProps {
  error?: CustomError,
  onPasswordChange: (current_password: string, password: string, password_confirmation: string, callback: () => void) => void
}

export const PasswordChange: React.FC<PasswordChangeProps> = ({ onPasswordChange, error }) => {

  const { t } = useTranslation();
  const [userPassword, setUserPassword] = useState<{ current_password: string, password: string, password_confirmation: string }>({
    current_password: "",
    password: "",
    password_confirmation: ""
  });
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
  const { loading } = useContext(UserContext);
  const passwordIsLoading = isLoading(loading, "password");

  const handleOnChangeLoginUser = useCallback((key: 'current_password' | 'password' | 'password_confirmation', value: string) => {
    setUserPassword(prevState => {
      prevState[key] = value;
      return { ...prevState };
    });
  }, []);

  const clearField = useCallback(() => {
    setUserPassword(prevState => {
        prevState.current_password = "";
        prevState.password = "";
        prevState.password_confirmation = "";
        return { ...prevState };
    });
  }, []);

  const handleOnChangePassword = useCallback(() => {
    const errors: { [key: string]: boolean } = {};
    errors["current_password"] = !userPassword.current_password;
    errors["password"] = !userPassword.password;
    errors["password_confirmation"] = !userPassword.password_confirmation || userPassword.password !== userPassword.password_confirmation;
    setErrors(errors);

    const errorKeys = Object.keys(errors);
    if (!errorKeys.some(key => errors[key])) 
    {
        onPasswordChange(userPassword.current_password, userPassword.password, userPassword.password_confirmation, clearField);
        
    }
  }, [clearField, onPasswordChange, userPassword.current_password, userPassword.password, userPassword.password_confirmation]);

  return (
          <Box  sx={{ m: 2, width: 250 }}>
            <Typography sx={{ mb: 2 }}>
            {t('changePassword')}
            </Typography>
            <Box>
              <TextField helperText={error?.messages[0]} sx={{ my: 1 }} label={t('currentPassword')} value={userPassword.current_password} error={errors["current_password"] || (error ? true : false)} onChange={(e) => handleOnChangeLoginUser("current_password", e.currentTarget.value)} type="password" fullWidth  />
            </Box>
            <Box>
              <TextField sx={{ my: 1 }} label={t('newPassword')} value={userPassword.password} error={errors["password"]} onChange={(e) => handleOnChangeLoginUser("password", e.currentTarget.value)} type="password" fullWidth  />
            </Box>
            <Box>
              <TextField sx={{ my: 1 }} label={t('newPasswordConfirmation')} value={userPassword.password_confirmation} error={errors["password_confirmation"]} onChange={(e) => handleOnChangeLoginUser("password_confirmation", e.currentTarget.value)} type="password" fullWidth  />
            </Box>
            <Box>
              {
                passwordIsLoading ?
                (<LoadingButton variant="contained" sx={{ my:2 }} loading >{t('changePassword')}</LoadingButton>) :
                (<Button sx={{ my: 1 }} variant="contained" onClick={handleOnChangePassword}>{t('changePassword')}</Button>)
              }
            </Box>
          </Box>
  );
}

export default PasswordChange;