export class Color {
    public id: number;
    public name: string;
    public code: string;

    constructor(other?: Partial<Color>){
        this.id = other?.id ?? 0;
        this.name = other?.name ?? "";
        this.code = other?.code ?? "";
    }
}