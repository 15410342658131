import React, { useState } from 'react';
import { UserContext } from './UserContext';
import { User } from 'Models';

export interface IUserState {
    user: User,
    loading: string[]
}
export const UserProvider: React.FC = ({children}) => {
    const [userState, setUserState] = useState<IUserState>({ user: new User(), loading: [] });
    return (<UserContext.Provider value={{ ...userState, setUserState }}> { children } </UserContext.Provider>)
}