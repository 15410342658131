import React, { useEffect, useContext, useState } from 'react';
import { ColorService, UserService } from 'Services';
import { Header, Board } from './';
import { Box } from '@mui/material';
import { ColorsContext, UsersContext, ActiveBoardProvider } from 'Contexts';

export const Container: React.FC = () => {
  const [boardId, setBoardId] = useState<number>();

  const { setColors } = useContext(ColorsContext);
  const { setUsers } = useContext(UsersContext);

  useEffect(() => {
    ColorService.GetColors()
    .subscribe(result => {
      setColors(result);
    });

    UserService.GetUsers()
    .subscribe(result => {
      setUsers(result);
    });
}, [setColors, setUsers]);

  return (
    <Box>
      <Header />
      <ActiveBoardProvider board_id={boardId}>
        <Board onChangeBoardId={(boardId => setBoardId(boardId))} />
      </ActiveBoardProvider>
    </Box>
  );
}

export default Container;