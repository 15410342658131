import React, { Dispatch, SetStateAction } from 'react';
import { User } from 'Models';

export interface UsersStateInterface {
    users: User[], 
    setUsers: Dispatch<SetStateAction<User[]>>, 
    isLoading: boolean
}

export const UsersContext = React.createContext<UsersStateInterface>({
    users: [],
    setUsers: () => {},
    isLoading: false,
});