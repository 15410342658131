import { ActiveBoardContext } from './ActiveBoardContext';
import { useActiveBoard } from 'Hooks';

export interface ActiveBoardProviderProps {
    board_id?: number;
}
export const ActiveBoardProvider: React.FC<ActiveBoardProviderProps> = ({children, board_id}) => {
    const activeBoard = useActiveBoard(board_id);

    return (
        <ActiveBoardContext.Provider value={activeBoard}>
            {children}
        </ActiveBoardContext.Provider>
    )
}