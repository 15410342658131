import React, { useCallback, useContext } from 'react';
import { UserService } from 'Services';
import { Settings } from './';
import { Box } from '@mui/system';
import { AppBar, Toolbar,Typography, IconButton, Dialog } from '@mui/material';
import { Logout, Settings as SettingsIcon } from '@mui/icons-material';
import { UserContext, LoginContext, SettingsContext } from 'Contexts';
import { Login, User } from 'Models';
import { removeLoading } from 'Utils'; 

export const Header: React.FC = () => {

  const { setUserState } = useContext(UserContext);
  const { login, setLogin } = useContext(LoginContext);

  //const [settingsOpen, setSettingsOpen] = React.useState(false);
  const { isOpen, setIsOpen } = useContext(SettingsContext);

  const handleSettingsOpen = () => setIsOpen(true);
  const handleSettingsClose = () => setIsOpen(false);

  const handleLogout = useCallback(() => {
    if (login.token) {
      UserService.Logout()
        .subscribe(() => {
          localStorage.removeItem("login");
          setLogin(new Login());
          setUserState(prevState => ({ user: new User(), loading: removeLoading(prevState.loading, "login") }));
        });
    }
    
  }, [login.token, setLogin, setUserState]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="primary" enableColorOnDark>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Whiteboard
          </Typography>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleSettingsOpen}
              color="inherit"
            >
              <SettingsIcon />
            </IconButton>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleLogout}
              color="inherit"
            >
              <Logout />
            </IconButton>
            <Dialog open={isOpen} onClose={handleSettingsClose} maxWidth="md" > 
              <Settings onCloseClick={handleSettingsClose} />
            </ Dialog>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;