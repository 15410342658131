import React, { Dispatch, SetStateAction } from 'react';
import { IUserState } from 'Contexts';
import { User } from 'Models';

export interface UserStateInterface {
    user: User, 
    setUserState: Dispatch<SetStateAction<IUserState>>, 
    loading: string[]
}

export const UserContext = React.createContext<UserStateInterface>({
    user: new User(),
    setUserState: () => {},
    loading: [],
});